<div class="pb-48 px-24">
    <div class="flex flex-col items-center px-48 pt-48">
        <div class="flex flex-col items-center max-w-md text-center">
            <h2 class="text-3xl md:text-4xl pb-20 text-dukeDarkBlue Roboto">Home Protection Plans</h2>
            <p>Select one or more plan(s) you're interested enrolling in.
            </p>
        </div>
    </div>

<div class="flex flex-wrap gap-y-12 justify-around mt-20">
    <div *ngFor="let plan of tiles" class="lg:w-[45%] mb-8 min-h-[200px]">
        <app-simple-card 
            (click)="clickOnPlan(plan)" 
            class="hover:cursor-pointer w-full h-full" 
            [cardTitle]="plan.name" 
            [description]="plan.description" 
            [cardIcon]="plan.image">
        </app-simple-card>
    </div>
</div>
</div>
