import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { CustomerService } from './services/customer.service';
import { Customer } from './shared/models/customer';

@Injectable({
  providedIn: 'root'
})
export class LocationGuard implements CanActivate {
  
  constructor(private _customerService:CustomerService, private router: Router) {
  }

  canActivate(): boolean {
    const customer:Customer = this._customerService.reloadCustomer();

    if (customer != undefined && customer.hasValidLocation()) {
      return true;
    } else {
      console.log('customer: ', customer)
      console.log('location check guard failed! navigating to the location page')
      this.router.navigate(['/location-check']);
      return false;
    }
  }
}