import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map, take } from 'rxjs';
import { ApiService } from './api.service';
import { LoaderService } from './loader.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthResponseInterceptorService implements HttpInterceptor {

  constructor(private _apiService: ApiService, private _loaderService: LoaderService, private _toastService: ToastService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(map((event: HttpEvent<any>) => {

          if (event instanceof HttpResponse && event.status == 403) {
            this.getNewToken();
          }
          return event;
      }));

  }

  private getNewToken() {
    this._loaderService.requestShowLoader();
    this._apiService.getNewToken().pipe(take(1)).subscribe({
      next: _ => {
        this._loaderService.requestHideLoader();
      },
      error: _ => {
        this._toastService.triggerErrorToast('Error connecting to Duke Energy Services.  Please refresh your window and try again.')
        this._loaderService.requestHideLoader();
      },
    })
  }
}
