import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PlanDetailsComponent } from './pages/plan-details/plan-details.component';
import { LoginComponent } from './pages/login/login.component';
import { WhichTierComponent } from './pages/tier/tier.component';
import { HomeComponent } from './pages/home/home.component';
import { SignInComponent } from './pages/login/sign-in/sign-in.component';
import { UnsupportedComponent } from './pages/unsupported/unsupported.component';
import { LocationCheckComponent } from './pages/location-check/location-check.component';
import { ShoppingCartComponent } from './pages/shopping-cart/shopping-cart.component';
import { EnrollmentOutcomeComponent } from './pages/enrollment-outcome/enrollment-outcome.component';
import { LocationGuard } from './location.guard';
import { loginCheckGuard } from './login-check.guard';



const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [LocationGuard]
  },
  {
    path: 'plan-details',
    component: PlanDetailsComponent,
    canActivate: [LocationGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginCheckGuard]
    
  },
  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [loginCheckGuard]

  },
  {
   path: 'shopping-cart',
   component: ShoppingCartComponent,
    canActivate: [LocationGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfileModule),
    canActivate: [LocationGuard]
  },
  {
    path: 'tier',
    component: WhichTierComponent,
     canActivate: [LocationGuard]
    
  },
  {
    path: 'unsupported',
    component: UnsupportedComponent,
  },
  {
    path: 'location-check',
    component: LocationCheckComponent,
  },
  {
    path: 'enrollment/:status',
    component: EnrollmentOutcomeComponent,
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //enableTracing: true,
     // useHash: true,
      initialNavigation: isInIframe() ? 'disabled' : undefined
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export function isInIframe() {
  return window !== window.parent && !window.opener;
}