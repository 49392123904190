import { Injectable } from '@angular/core';
import { PlanTile } from '../Models/HomeContent';
import { BehaviorSubject, Observable } from 'rxjs';
import {Plan, Tier} from '../Models/PlansContent';

@Injectable({
    providedIn: 'root'
})
export class CacheService {

  private _formData: any;
  private _plans = new BehaviorSubject<PlanTile[]>([]);

  constructor() {
    const plans = JSON.parse(this.retrieve('plans'));
    if (this._plans) {
      this._plans.next(plans);
    }
   }

  public setFormData(formData: any): void {
    this._formData = formData;
  }

  public getFormData(): any {
    return this._formData;
  }
  public savePlans(plans:PlanTile[]) {
    const content = JSON.stringify(plans);
    if(this.store('plans', content)){
      this._plans.next(plans);
    }
  }

  public get rawPlans():PlanTile[] {
    const stringData = sessionStorage.getItem('plans');
    const data:PlanTile[] = JSON.parse(stringData)
    return data
  }

  public getFamilyPlanID(productCode: string): string{
    const familyId = '';
    const currentPlans = this.rawPlans;
    for (const plan of currentPlans){
       for (const code of plan.productCodes) {
          if (code == productCode) {
              return plan.id
          }
       }
    }

    console.log('no family id found')
    return familyId;
  }

  public getProductDetail(categoryID: string): Plan{
    const currentPlans = this.rawPlans;
    const plan:PlanTile = currentPlans.find(plan => plan.id === categoryID);
    return plan.productDetails;
  }

  private store(key:string, value:string): boolean {
    sessionStorage.setItem(key, value);
    return sessionStorage.getItem(key).length > 0;
  }

  private retrieve(key:string): any {
    return sessionStorage.getItem(key);
  }


  get plans(): Observable<PlanTile[]> {
    return this._plans.asObservable();
  }
}
