import { Component, OnDestroy, OnInit } from '@angular/core';
import { DisclaimerComponent } from '../../disclaimer-component/disclaimer.component';
import { DataSource } from '../../Models/DataSource';
import { Plan } from '../../Models/PlansContent';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, Subscription, fromEvent, takeUntil } from 'rxjs';
import { HomeContent } from '../../Models/HomeContent';
import { ToastService } from '../../services/toast.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ApiService } from "../../services/api.service";
import { LoaderService } from "../../services/loader.service";
import { Customer } from 'src/app/shared/models/customer';
import { PlanDetailsRequest } from './plan-details-models';
import { CacheService } from 'src/app/services/cache.service';

@Component({
  selector: 'app-tier',
  standalone: true,
  imports: [DisclaimerComponent, CommonModule, SharedModule],
  templateUrl: './tier.component.html',
  styleUrl: './tier.component.scss',
})
export class WhichTierComponent implements OnInit, OnDestroy {
  public homeContent: HomeContent = DataSource.homeContent();
  plan: Plan;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription;
  public currentCategoryID: string = '';
  public isNotMobileLayout = false;
  public selectedItem = -1;
  public preSelectedItemId = ''
  public customer: Customer;
  protected _onDestroy = new Subject<void>();
  private _subs: Subscription[] = [];
  public buttonTitle = 'Add Plan';


  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _toastService: ToastService,
    private _customerService: CustomerService,
    private _apiService: ApiService,
    private _loaderService: LoaderService,
    private _cacheService: CacheService,

  ) { }


  ngOnInit(): void {
    // TODO: unsubscribe safely
    this._subs.push(this.route.queryParams.subscribe(_ => {
    }),
      this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(_ => {
      })
    )
    this._cacheService.rawPlans ? this.fetchCachedPlanDetails() : this._router.navigate(['home']);
  }

  findIfPlanIsAlreadyPreselected(): boolean {
    this.selectedItem = this.plan.content.tiers.findIndex(tier => this._customerService.hasPlan(tier.id));
    return this.selectedItem >= 0;
  }

  findOutPreselectedPlanId(): string | undefined {
    return this.plan.content.tiers.find(tier => this._customerService.retrievePlan(tier.id))?.id
  }


  handleMainButtonAction():void {
    const planID:string = this.plan.content.tiers[this.selectedItem].id;
    if (planID === this.preSelectedItemId) {
      this._router.navigate(['shopping-cart']);
    } else if (this.preSelectedItemId) {
      this.updatePlan()
    } else {
      this.addPlan()
    }
  }

  fetchCachedPlanDetails(): void {
    const categoryID = this.route.snapshot.queryParams['plan'] as string;
    this.plan = this._cacheService.getProductDetail(categoryID);
    this.preSelectedItemId = this.findOutPreselectedPlanId();
    this.formatPlan();
    if (this.findIfPlanIsAlreadyPreselected()) {
      this.buttonTitle = 'Return to Cart';
    }
  }


  formatPlan(): void {
    this.isNotMobileLayout = window.innerWidth >= 768;
    this.resizeObservable$ = fromEvent(window, 'resize')
    this._subs.push(this.resizeSubscription$ = this.resizeObservable$.subscribe(_ => {
      this.isNotMobileLayout = window.innerWidth >= 768;
    }));
    this._loaderService.requestHideLoader()
  }

  clickOnActionButton(index: number): void {
    this.selectedItem = this.selectedItem == index ? 1 : index;
    const planID:string = this.plan.content.tiers[this.selectedItem].id;
    if (planID === this.preSelectedItemId) {
      this.buttonTitle = 'Return to Cart'
    } else if (this.preSelectedItemId) {
      this.buttonTitle = 'Update Plan'
    } else {
      this.buttonTitle = 'Add Plan'

    }
  }


  onClickTCLink() {
    window.open(this.plan.content.planTile.tcLink, '_blank');
  }

  addPlan(): void {

    // TODO: maybe also check for a selected plan?
    if (this.selectedItem == -1) {
      this._toastService.triggerErrorToast('Please select a plan to move forward');
    } else {
      this._toastService.triggerSuccessToast('Plan successfully added');
      const planID: string = this.plan.content.tiers[this.selectedItem].id;
      this._customerService.addPlan(planID);
      this._router.navigate(['shopping-cart']);
    }
  }
  //Adding the updating plan option, not sure if SAP will need to track these things....
  updatePlan(): void {
    for (const tier of this.plan.content.tiers) {
      this._customerService.removePlan(tier.id);
    }

    const planID: string = this.plan.content.tiers[this.selectedItem].id;
    this._customerService.addPlan(planID);
    this._toastService.triggerSuccessToast('You have successfully updated your plan.');
    this._router.navigate(['shopping-cart']);
  }

  maintainPlanContent(): void {
    this._router.navigate(['shopping-cart']);
  }

  goPrevious(): void {
    this._router.navigate(['home']);
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe());
  }

}



// TODO: move to models
export interface EdsSelectOption {
  id: number,
  displayText: string,
  value: string,
}

