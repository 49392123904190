import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, take, takeUntil } from 'rxjs';
import { AddressResult, AddressSearchComponent } from 'src/app/address-search/address-search.component';
import { ModalMessageComponent } from 'src/app/components/modal-message/modal-message.component';
import { ApiService } from 'src/app/services/api.service';
import { CustomerService } from 'src/app/services/customer.service';
import { ToastService } from 'src/app/services/toast.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddressValidationRequest } from './location-models';
import { LoaderService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-location-check',
  standalone: true,
  imports: [SharedModule, AddressSearchComponent, ModalMessageComponent],
  templateUrl: './location-check.component.html',
  styleUrl: './location-check.component.scss'
})
export class LocationCheckComponent implements OnInit, OnDestroy {
 @ViewChild('modalTemplate') locationModal: any;

  eligForm: FormGroup;
  hasAddedPlans = false;
  public residentialControl = new FormControl('residential');
  public showAparmentNumberModal = false;
  isResidential = undefined;
  typeOfProperty = undefined;
  isOwner = undefined;
  userAddress: AddressResult = undefined;
  protected _onDestroy = new Subject<void>();



  constructor(
    private _router: Router,
    private _loaderService: LoaderService,
    private _customerService: CustomerService,
    private route: ActivatedRoute,
    private _apiService: ApiService,
    private _toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,) {
      this.buildForm();
  }

  buildForm(): void {
    this.eligForm = new FormGroup({
      residential: new FormControl(''),
      typeOfProperty: new FormControl(''),
      ownOrRent: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => {
      params.getAll('plan').forEach((plan) => {
        this.onProductCodeFound(plan);
      });
    });
    this._customerService.customer.pipe(takeUntil(this._onDestroy)).subscribe(cust => {
      //Not sure what this error is but research has begun.
      this.hasAddedPlans = (cust?.addedPlans?.length > 0) ?? false;
    })
    this.changeDetectorRef.detectChanges();
  }


  answerForAddress(address: AddressResult): void {
    this.userAddress = address;
  }

  answerForResidentialQuestion(event: string): void {
    this.isResidential = !event.includes('Non-Res');
  }

  onProductCodeFound(plan: string){
    this._customerService.addPlanByid(plan);
  }

  answerForTypeOfPropertyQuestion(event: string): void {
    // TODO: replace with enum
    if (event.includes('Single')) {
      this.typeOfProperty = 'Single Family'
    } else if (event.includes('Multi')) {
      this.typeOfProperty = 'Multi-family'
    } else if (event.includes('Mobile')) {
      this.typeOfProperty = 'Mobile Home'
    }
  }

  answerForOwnOrRentQuestion(event: string): void {
    this.isOwner = event.includes('Own');
  }

  guardForValidAddress(): boolean {
    return (!this.userAddress || !this.userAddress?.zipCode || !this.userAddress?.city || !this.userAddress?.street || !this.userAddress?.state) ? true : false;
  }

  guardForAllAnswersCompleted(): boolean {
    return (this.isResidential === undefined || this.typeOfProperty === undefined || this.isOwner === undefined) ? true : false;
  }

  public continueActionClicked(): void {
    // console.log()
    if (this.guardForValidAddress()) {

      this._toastService.triggerErrorToast('Please enter a valid address.');
    } else if (this.guardForAllAnswersCompleted()) {

      this._toastService.triggerErrorToast('Please answer the questions above.')
    } else {
        this.performValidServiceAddressheck()
    }

  }

  public valueOrNull(value:string){
      if(value == undefined){
        return undefined;
      }

      if (value.length == 0) {
          return undefined
      }

      return value;
  }

  // Checks to see if the input address is a valid address for DEONE use.
  public performValidServiceAddressheck() {
    console.log(this.userAddress)
    const request:AddressValidationRequest = {
                                                street_address:this.userAddress.street,
                                                house_number:  '',
                                                city:          this.userAddress.city,
                                                state:         this.userAddress.state,
                                                zip_code:      this.userAddress.zipCode,
                                                apartment:     this.userAddress.apartment
                                              }
  this._loaderService.requestShowLoader();
  this._apiService.validateServiceAddress(request).pipe(take(1)).subscribe({
          next: response => {
            this._loaderService.requestHideLoader();
            if(response.is_valid && !response.is_native){
             // a valid non-native address is a good de-one address
             const validatedAddress:AddressResult = {
                house_number: this.valueOrNull(response.address.house_number) ?? this.userAddress.house_number, 
                street: this.valueOrNull(response.address.street_address) ?? request.street_address,
                apartment: this.valueOrNull(response.address.apartment) ?? request.apartment,
                city: request.city,
                state:request.state,
                zipCode:request.zip_code
              }

              this._customerService.updateEligibility(validatedAddress, this.isResidential, this.typeOfProperty, this.isOwner);
              const showApptPrompt:boolean = (response.appartment_number_prompt && this.userAddress.apartment.length == 0);
              this.showAparmentNumberModal = showApptPrompt;
              if(!showApptPrompt) {
               /// apartment number not required just proceed to home
               this._router.navigate(['home']);
              }
            } else if(response.is_valid && response.is_native) {
              // address is valid but not de-one material, show the duke territory error
               this._router.navigate(['unsupported'], { queryParams: { reason: 'duke-energy'} });
            }  else if(!response.is_valid) {
              // address is all together invalid show not-eligible screen
               this._router.navigate(['unsupported'], { queryParams: { reason: 'not-eligible'} });
            }
          },
          error: err => {
            this._loaderService.requestHideLoader();
            this._toastService.triggerErrorToast('Unknown Error Validating addresss');
            console.error(err);
          }
        });
  }

  public previousActionClicked() {
    this._router.navigate(['home']);
  }

  public edit(): void {
    this.showAparmentNumberModal = false;
  }

  public continue(): void {
    this.showAparmentNumberModal = false;
    // capture the appartment number if the user entered one. Note: it could be empty as they are allowed to skip that step even if they should
    this._customerService.updateAppartmentNumber(this.userAddress.apartment);

    this.showAparmentNumberModal = false;
    if(this.hasAddedPlans){
       this._router.navigate(['shopping-cart']);
    }
   else {
    this._router.navigate(['home']);
   }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
  }






  // TODO: CODE NOT FOR PROD
  // AGAIN: NOT FOR PROD
  // ONLY FOR TESTING!!!!!
  populateAddressForTesting(): void {
    console.log('hello world')
    this.userAddress = { apartment: "", city: "Woodstock", house_number: "701", state: "GA", street: "701 Kirkwood Circle", zipCode: "30189" }
    this.typeOfProperty = 'Single Family'
    this.isResidential = true;
    this.isOwner = true;
    this._customerService.updateEligibility(this.userAddress, this.isResidential, this.typeOfProperty, this.isOwner);
    this._router.navigate(['home']);
  }

}
