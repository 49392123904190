<ng-container>
  <header
    class="site-header__container sticky top-auto z-header p-8 border-b border-gray md:mt-10"
    [ngClass]="[
      this.justScrolledDown && this.allowScrollAnimation
        ? '-top-[175px]'
        : 'top-0',
      this.hasScrolledDown ? 'shadow' : ''
    ]"
  >
    <ng-container>
      <div class="site-header__action-bar flex bg-white text-blue md:ml-6">
        <div class="site-header__action-bar--left flex items-center">
          <button
            *ngIf="navDrawerBody"
            id="hamburger-trigger--external"
            class="btn btn-tertiary btn-icon-only btn-small mx-4"
            aria-label="Open Navigation Drawer"
            aria-controls="nav-drawer"
            [attr.aria-expanded]="isHamburgerMenuOpen"
            (click)="toggleHamburgerMenuOpen()"
          >
            <eds-svg
              svgPath="components/assets/icons/hamburger-menu.svg"
            ></eds-svg>
          </button>

          <eds-svg
            [svgPath]="
              headerIcon?.path ?? '/assets/icons/duke-energy-one-logo.svg'
            "
            [svgSizeClass]="headerIcon?.sizeClass ?? 'icon-72'"
          ></eds-svg>
          <div
            class="site-header__title text-title border-l border-gray ml-10 pl-12 md:text-xl text-lg"
          >
            {{ siteTitle }}
          </div>
        </div>

        <!--Nav Bar-->
        <nav aria-label="navbar" class="navbar px-6 md:ml-auto">
          <div  class="container flex justify-between items-center">
            <span *ngIf="hasLocation">
            <div class="flex items-center md:ml-64">
              <eds-svg
                [svgPath]="'assets/icons/shield-base.svg'"
                [attributes]="{ fill: 'currentColor' }"
                [svgSizeClass]="'icon-24'"
              ></eds-svg>

              <app-tab-dropdown
                details="All Plans"
                [navGroups]="headerNavGroups"
              >
              </app-tab-dropdown>
            </div>
          </span>
<!--Empty Cart-->
            <ul class="overflow-hidden flex md:gap-24">
              <span *ngIf="hasLocation">
              <li class="flex md:space-x-6">
                <eds-svg
                  [svgPath]="'assets/icons/shopping-cart.svg'"
                  [attributes]="{ fill: 'currentColor' }"
                  [svgSizeClass]="'icon-28'"
                ></eds-svg>
                <div class="cursor-pointer" (click)="cartFunc()"> Cart ({{ plansCount}})</div>
              </li>
            </span>
              <li *ngIf="isAbleToSignOut" class="flex md:space-x-6">
                <eds-svg
                  [svgPath]="'assets/icons/individual-base.svg'"
                  [attributes]="{ fill: 'currentColor' }"
                  [svgSizeClass]="'icon-24'"
                ></eds-svg>
                <a (click)=signCustomerOut() class="hover:cursor-pointer">Sign Out</a>
                <!-- <a href="/sign-in" class="">Sign Out</a> -->
              </li>
            </ul>
          </div>
        </nav>

        <!-- Left-Nav Drawer -->
        <nav class="leftNav">
          <eds-drawer
            *ngIf="navDrawerBody"
            id="nav-drawer"
            [isDrawerOpen]="isHamburgerMenuOpen"
            [openFrom]="'left'"
            [title]="hamburgerMenuTitle"
            [body]="navDrawerBody"
            (dismiss)="isHamburgerMenuOpen = false"
          >
          </eds-drawer>
        </nav>
      </div>
    </ng-container></header
></ng-container>
