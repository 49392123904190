import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {jwtDecode} from 'jwt-decode';
@Injectable({
  providedIn: 'root'
})

export class TokenService {

  constructor() { }
  getToken():string |null{
    return sessionStorage.getItem('session_access_token');
  }
  getRefreshToken():string |null{
    return sessionStorage.getItem('session_refresh_token');
  }

  isTokenExpired(token:string): boolean {
    try {
      const decodedToken =  jwtDecode(token);
      const currentTime = Math.floor(Date.now() /1000);
      return decodedToken.exp < currentTime
    }catch (error){
      return true
    }
  }

  endureValidRefreshToken():boolean {
    const refreshToken = this.getRefreshToken();
    return  this.isTokenExpired( refreshToken);
  }

  ensureValidToken():boolean {
    const token = this.getToken();
    return  this.isTokenExpired(token);
  }


}
